.s1v1-content {
  margin-top: 175px;
  margin-bottom: 132px;
}
.s1v1-header {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  margin-top: 24px;
}
.s1v1-sub-header {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 24px;
  margin-bottom: 48px;
}
.s1v1-youtube {
  margin-top: 142px;
  padding: 15px;
  border-radius: 24px;
  background: rgba(15, 15, 15, 0.3);
}
.s2-container-dm,
.s2-container-rm {
  margin-top: 80px;
}
.s2-container-rm {
  margin-bottom: 52px;
}
.s-head-dm {
  display: flex;
  padding: var(--0, 0px);
  align-items: flex-start;
  gap: 23px;
}
.s-head-dm .t1 {
  font-size: 16px;
  font-weight: 700;
}
.s-head-dm .t2 {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 700;
}
.s2-dm-bdr {
  margin-top: 6px;
  margin-bottom: 24px;
  width: 200px;
  height: 3px;
  border-radius: 6px;
}
.s2-dm-bdy-text {
  color: var(--primary-black, #1a1a1a);
  font-size: 16px;
  font-weight: 400;
}
.mt-160{
  margin-top: 70px !important;
}

.roi-form {
  border-radius: 24px;
  background: linear-gradient(180deg, #173ba1 0%, #021f73 100%);
  padding: 56px 48px;
  margin-bottom: 52px;
}
.roi-form .t1 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 0px !important;
}
.roi-form .t2 {
  font-size: 18px;
  font-weight: 400;
}
.roi-form form p {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px !important;
}
.roi-form form .inputbox {
  padding: var(--35, 14px) var(--4, 16px);
  border-radius: var(--rounded-lg, 8px);
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--gray-50, #f9fafb);
  width: 245px;
  height: 52px;
}
.roi-form form button {
  width: 245px;
  height: 48px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  background: var(--blue, #3c6bf3);
}
.roi-cal-result .t1 {
  font-weight: 500;
}
.roi-cal-result .t2 {
  font-size: 16px;
  margin-bottom: 24px;
}
.roi-cal-result .t1 font {
  color: #00ffa0 !important;
  font-weight: 700 !important;
}
.roi-cal-result .cal-again-btn {
  border-radius: 8px;
  border: 1px solid #808080;
  padding: 8px 12px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  width: max-content;
  margin-top: 24px;
  margin-bottom: 24px;
  cursor: pointer;
}
.AI-hero-img {
  margin-top: 120px;
  margin-left: -70px;
}

.finance-details-container {
  margin-top: 10% !important;
}

.pm-h-img .pm-image{
  margin-left: 60px;
  width: 700px;
  border-radius: 16px;
  margin-top: 20px;
}
/* Mobile View */

@media screen and (max-width: 767px) {
  .s1v1-content {
    margin: 100px 0 0 10px;
  }
  .s1v1-header {
    font-size: 32px;
    margin-top: 20px;
  }
  .s1v1-sub-header {
    margin: 20px 0 20px 0;
  }
  .s1v1-youtube {
    margin-top: 32px;
  }
  .order-sm-2{
    order: 2;
  }
  .s2-container-dm,
  .s2-container-rm {
    margin-top: 50px;
  }
  .s2-container-rm {
    margin-bottom: 50px;
  }
  .s-head-dm {
    gap: 14px;
  }
  .s-head-dm .t2 {
    font-size: 22px;
  }
  .s2-dm-bdr {
    margin-top: 0px;
    margin-bottom: 18px;
  }
  .s2-dm-bdy-text {
    color: var(--primary-black, #1a1a1a);
    font-size: 16px;
    font-weight: 400;
  }
  .mt-160{
    margin-top: 40px !important;
  }
  .roi-form {
    height: 550px;
    padding: 20px;
    margin: 10px 10px 50px 10px;
  }
  .roi-form .text-center{
    text-align: left !important;
  }
  .roi-form .t1 {
    font-size: 22px;
    margin-bottom: 10px !important;
  }
  .roi-form .t2 {
    font-size: 16px;
  }
  .roi-form .d-flex{
    display: grid !important;
    gap: 20px;
    justify-content: left !important;
  }

  .roi-form form .inputbox {
    width: 300px;
    height: 44px;
  }
  .roi-form form button {
    width: 300px;
    height: 44px;
    margin-bottom: 10px;
  }
  .roi-cal-result .btn-with-arrow{
    padding: 10px 20px;
  }
  .roi-cal-result .t1{
    font-size: 26px;
  }
  .roi-cal-result .t2{
    font-size: 20px;
  }
  .roi-cal-result .t1, .roi-cal-result .cal-again-btn, .roi-cal-result .btn-with-arrow, .roi-cal-result .t2{
    margin-top: 30px;
  }
  
  .AI-hero-img {
    margin-top: 40px;
    margin-left: 0px;
  }
  .AI-hero-img img{
    width: 105%;
  }
  .pm-h-img .pm-image{
    margin-bottom: 40px;
    margin-left: 0;
    width: 100%;
  }
}
