.s1-container,
.s2-container,
.s3-container,
.s4-container,
.s5-container,
.s6-container,
.press-slider-container,
.footer-container {
  overflow-x: clip;
}
.color-green {
  color: var(--green-300, #377e36);
}
.color-red {
  color: var(--error-red, #c83232);
}
.color-brand {
  color: #f36513;
}
.color-blue{
  color: #1A56DB;
}
.color-purple{
  color: #6C42C1;
}
.fs-16px {
  font-size: 16.5px;
}
.fs-14 {
  font-size: 14px;
}
.fs-22{
  font-size: 22px;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.f-width {
  width: 1200px;
}
.text-align-w-right {
  text-align: -webkit-right;
}
.mb60px{
  margin-bottom: 60px;
}
/*--------------- Section1 css starts ----------------------*/
.top-alert-home{
  top: 100px;
  background: #f3f3f3;
  box-shadow: 0px 8.34782600402832px 25.043479919433594px 0px rgba(54, 54, 54, 0.1);
  color: #1a1a1a;
  font-weight: 600;
  padding: 10px 20px;
  border-color: #f3f3f372;
}
.top-alert-home span{
  font-size: 16px;
}
.s1-container {
  background-image: url("../images/heroBG.webp");
  background-size: 100%;
  background-repeat: round;
}
.bg-effect{
  background-image: url("../images/noise.png");
}
.float-imgs{
  top: 130px;
  display: flex;
  gap: 20px;
  justify-content: center;
}
.float-imgs img{
  height: 70px;
  opacity: 0.7;
  border-radius: 5px;
}
.hero-data {
  padding: 150px 110px 233px;
}
.caption-text div{
  font-size: 1rem;
  color: #f2f2f2;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 8.34782600402832px 25.043479919433594px 0px
    rgba(54, 54, 54, 0.1);
}
.tooltip-inner{
  background-color: #060505;
  max-width: 320px !important;
}
.hero-primary-text {
  font-size: 5rem;
  line-height: 6.0rem;
  font-weight: 500;
  color: #f2f2f2;
  padding-bottom: 24px;
}
.hero-secondary-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--line, #e6e6e6);
  padding-bottom: 32px;
}

.shake-effect {
  display: inline-block;
  font-weight: 600;
  line-height: 80px;
  border-radius: 30px 10px;
  box-shadow: 0px 8.34782600402832px 25.043479919433594px 0px
    rgba(54, 54, 54, 0.1);
  color: #FFD4BA;
  background-image: linear-gradient(90deg, #f9d3bb 22%, #759af7 73%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* animation: skew-x-shake 1.8s infinite; */
}
@keyframes skew-x-shake {
  0% { transform: skewX(-15deg); }
  5% { transform: skewX(15deg); }
  10% { transform: skewX(-15deg); }
  15% { transform: skewX(15deg); }
  20% { transform: skewX(0deg); }
  100% { transform: skewX(0deg); }  
}

.s1-container .btn-primary {
  border-radius: 8px;
  padding: 14px 24px;
  justify-content: center;
  width: 300px;
}
.s1-container .btn-text {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding-right: 8px;
}
.s1-container .btn-primary{
  transition: scale 500ms;
}
.s1-container .btn-primary:hover{
  scale: 1.05;
}
.n-cc-r{
  color: #ffffffad;
  font-size: 0.875rem;
  font-weight: 600;
  padding-top: 10px;
}
.m-header{
    color: #292b2c;
    font-size: 42px;
    font-weight: 700;
}
.modal-backdrop.show {
  opacity: 1 !important;
  background-image: url('../images/ModalBG.png');
  filter: brightness(0.3);
}
.modal-content{
  width: 75%;
  margin: auto;
  padding: 0 30px 0 30px;
}
.s1-container .btn svg {
  vertical-align: bottom;
}
.s2-container .h-card1 {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 8.34782600402832px 25.043479919433594px 0px
    rgba(54, 54, 54, 0.1);
  padding: 12px;
}
.card-block1 {
  border-radius: 12px;
  background: #fff;
  padding-top: 8px;
}
.card-block2 {
  border-radius: 12px;
  background: #fff;
  padding-top: 8px;
}
.t-ded {
  font-size: 14px;
  position: absolute;
  right: 127.4px;
  top: 92.394px;
}
/* -------------------------need to change for s2-container ------------------------- */
.s2-container {
  margin-top: -153px;
}
/* .s2-container-slider {
  margin-top: -160px;
}
.s2-container {
  margin-top: 53px;
} */
/* -------------------------need to change for s2-container ------------------------- */

.s2-container .card-head-text img {
  margin-left: 0px;
  filter: brightness(1.0);
}
.s2-container .card-head-text p {
  color: var(--background-grey, #f3f3f3);
  font-size: 16px;
  font-weight: 700;
  margin: auto;
  margin-left: 14px;
}
.card-sub-text1 {
  color: var(--primary-black, #1a1a1a);
  font-size: 16.5px;
  font-weight: 600;
  margin: 0px;
  text-align: start;
}
.card-sub-text2 {
  color: var(--secondary-grey, #808080);
  font-size: 32px;
  font-weight: 500;
  text-align: start;
  margin: 0px;
}
.card-sub-text3 {
  color: var(--gray-400, #7a7a7a);
  font-size: 16.696px;
  font-weight: 400;
  text-align: start;
  margin: 0px;
}
.cta-btn {
  padding: 10.435px 16.696px;
  border-radius: 104.348px;
  background: #feede3;
}
.market-brand {
  vertical-align: super;
  width: inherit;
}
.graph_img {
  width: 220px;
}
.s3-container {
  margin-top: 5px;
}
.section2_brands {
  padding: 20px 0px !important;
}
.s2b_text {
  color: var(--secondary-grey, #808080);
  font-size: 20px;
  font-weight: 400;
}
.atop-size{
  width: 24px;
  height: 24px;
}
/* Brands logo animation */
/* .logo-slider {
  --image-size: 90px;
  padding: 20px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}
.logo-slider:hover div {
  animation-play-state: paused;
}
.logo-slider div {
  display: flex;
  position: relative;
  animation: marquee 40s linear infinite;
  justify-content: space-around;
}
.logo-slider img {
  display: block;
  min-width: var(--image-size);
  height: var(--image-size);
  margin: 0 2vw;
}
.logo-slider:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
} */

/* .brand-list{
  overflow: hidden;
}
.wrapper{
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 6.5rem;
  justify-items: stretch;
  align-items: center;
  gap: 3rem;
}

.wrapper{
  animation: scroll 40s linear infinite;
}
.wrapper:hover {
  animation-play-state: paused;
}
@keyframes scroll {
  to {
    translate: calc(-23 * 9.5rem);
  }
} */

.brands-list {
  overflow: hidden; 
  position: relative;
  width: 100%;
}

.wrapper {
  display: flex; 
  gap: 3rem;
  animation: scroll 40s linear infinite;
  align-items: center;
  will-change: transform;
}

.wrapper:hover {
  animation-play-state: paused;
}

.wrapper img {
  flex-shrink: 0;
  width: 6.5rem;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-23 * 9.5rem));
  }
}


/* Brand logo animation ends */
.s4-container {
  margin-top: 20px;
}
.d-card-wrap {
  padding: 32px;
  background: #fbfbfb;
  box-shadow: 0px 8px 24px 0px rgba(54, 54, 54, 0.1);
  border-radius: 12px;
}

.h-t1 {
  color: var(--primary-black, #808080);
  font-size: 16px;
  font-weight: 700;
}
.h-t2 {
  color: #1a1a1a;
  font-size: 36px;
  font-weight: 700;
  padding-top: 18px;
}
.h-t3 {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 400;
}
.review_manager_card {
  border-radius: 12px;
  box-shadow: 0px 8px 24px 0px rgba(54, 54, 54, 0.1);
  padding: 32px;
}
.bg-blue-grad {
  background: var(
    --blue-gradient,
    linear-gradient(180deg, #3c6bf3 0%, #021f73 100%)
  );
}
.bg-orange-grad {
  background: var(
    --orange-gradient,
    linear-gradient(180deg, #ec5701 0%, #d03e30 100%)
  );
}
.review_manager_card .h-t2 {
  font-size: 36px !important;
  line-height: normal;
  padding-top: 18px;
}
.review_manager_card .h-t3 {
  padding-bottom: 0 !important;
}
.brands-btn , .brands-btn2 {
  display: flex;
  gap: var(--25, 10px);
  padding-top: 25px;
  padding-bottom: 25px;
}
.brands-btn3 {
  display: flex;
  justify-content: space-around;
  gap: var(--25, 15px);
}
.brands-btn3 div {
  padding: 8px;
  font-size: 18px;
  font-weight: 700;
  min-width: 90px;
}
.brands-btn div {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.15);
  padding: 8px;
  color: #e6e6e6;
  font-size: 12px;
  font-weight: 700;
}
.brands-btn2 div {
  border-radius: 4px;
  background-color: rgba(226, 77, 27, 1);
  padding: 8px;
  color: #e6e6e6;
  font-size: 12px;
  font-weight: 700;
}
.btn-white-outlined {
  border-radius: var(--rounded-lg, 8px);
  border: 1px solid var(--white, #fff);
  padding: 14px 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  width: fit-content;
  margin-top: 60px;
  cursor: pointer;
}
.coming-soon-btn {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.2);
  padding: 14px 24px;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  width: fit-content;
  margin-top: 30px;
}
.finance-recon {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 8px 24px 0px rgba(54, 54, 54, 0.1);
  padding: 32px;
}
.finance-recon .fgif {
  margin-top: 32px;
}
.finance-recon .card-btn {
  padding-top: 52px;
  padding-bottom: 0px;
}
.dispute-card .card-btn{
  padding-top: 48px;
}
.btn-outline-primary {
  color: #1155cb;
}
.s4-container .btn-outline-primary:hover {
  color: #1155cb;
  background-color: #fff;
  text-decoration: none;
}
.img-card2 {
  height: 700px;
  width: -webkit-fill-available;
}
.img-card3 {
  height: 680px;
}
.img-card4 {
  width: 600px;
}
.s4-text {
  color: #000;
  font-size: 24px;
  font-weight: 600;
}
.btn-with-arrow {
  border-radius: 8px;
  color: var(--blue, #3c6bf3);
  font-size: 16px;
  font-weight: 500;
  padding: 14px 24px;
  display: inline-flex;
}

.s4-small-card {
  padding: 80px 20px;
}
.p-24 {
  padding: 24px;
}

/* --------------Section4 css ends------------------ */
.s5-container {
  padding-bottom: 20px;
  /* padding-bottom: 100px; */
}
.v-all-btn{
  font-size: 18px;
  padding: 10px 18px;
}
.v-all-btn:hover{
  background-color:transparent !important;
}
.section-head {
  color: var(--primary-black, #1a1a1a);
  font-size: 35px;
  font-weight: 700;
}
.section-subhead {
  color: var(--primary-black, #1a1a1a);
  font-size: 18px;
  font-weight: 400;
}
.s5-container .card-title {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  padding-right: 10px;
}
.s5-container .card {
  max-width: 380px;
  min-height: 500px;
  border-radius: 12px;
  border: none;
}
.s5-container .card-text {
  border-radius: 8px;
  background: var(--background-grey, #f3f3f3);
  padding: 12px;
}
.s5-container .card-text .t1 {
  color: var(--secondary-grey, #808080);
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
.s5-container .card-text .t2 {
  color: var(--gray-400, #7a7a7a);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.case-studies-slider {
  width: 1250px !important;
  margin-top: 50px;
}
/* .slick-slider .slick-prev {
  display: none !important;
  font-size: 2vw;
  color: black;
} */
/* .slick-slider .slick-next {
  display: none !important;
  font-size: 2vw;
  color: black;
} */

.slick-prev:before {
  color: black;
  font-size: 1.8vw;
}

.slick-next:before {
  color: black;
  font-size: 1.8vw;
}

@media screen and (max-width: 500px) {
  .slick-prev:before {
    display: none;
  }

  .slick-next:before {
    display: none;
  }
}

.slick-list {
  margin: 0 10px;
}

.slick-dots li button::before {
  content: none !important;
}
.slick-dots {
  bottom: -50px;
}
.slick-dots li {
  margin-left: -10px;
}
.slick-dots li button {
  background-color: rgba(179, 179, 179, 1);
  border-radius: 5rem;
  width: 10px;
  height: 10px;
}
.slick-dots .slick-active {
  margin-right: 30px;
}
.slick-dots .slick-active button {
  width: 35px;
}
.s5-container .btn-outline-primary:hover {
  color: #1155cb;
  background-color: #fff;
  text-decoration: none;
}
.s6-container {
  background: rgba(0, 0, 0, 1);
  padding-top: 120px;
  padding-bottom: 120px;
}
.ytubee .yt-thumb {
  width: 100%;
}
.video_frame {
  width: 100%;
  height: 380px;
  box-shadow: 20px 20px 25px 0px #0000001a;
  border-radius: 22.3217px;
}
.ytubee {
  position: relative;
  text-align: center;
}
.thumb-text-btn {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3.93%, #000000 100%);
  color: #fff;
  font-size: 24px;
  border-radius: 15px;
  padding: 0 23px 20px 23px;
  cursor: pointer;
}
.play_btn {
  margin-left: auto;
  background-color: rgba(255, 255, 255, 0.36);
  padding: 16px;
  border-radius: 30px;
}
.testimonials {
  display: flex !important;
  padding: 24px;
  align-items: flex-start;
  gap: 19px;
  border-radius: 12px;
  border: 1px solid var(--secondary-grey, #808080);
  background: var(--primary-black, #1a1a1a);
  margin-top: 45px;
}
.testimonials .t-profile {
  align-self: center;
  height: 50px;
  border: 3px solid #fff;
  border-radius: 25px;
}
.testimonials .t-bite {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
}
.form-section {
  padding: 0 50px 0 50px;
}
.form-header {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  text-align: left;
}

/* ------------------- Press section css starts ------------------- */
.press-slider-container .press-slider{
  padding-bottom: 100px;
}
.press-slider-container .slick-slider{
  width: 1240px !important;
}
.press-slider-container .card-img-top{
    padding: 50px 130px;
}
.press-cards .card-img-top{
  background-image: url('../images/press/press_card_bg.png');
}
.press-cards .card{
  width: 386px !important;
  margin-bottom: 40px;
}
.press-slider-container .card-title{
  font-size: 18px;
}
.press-slider-container .card-text {
  font-size: 14px;
  color: #7a7a7a;
  background:none;
  padding: 0px;
}
.press-slider-container .btn-with-arrow {
  font-size: 18px;
  padding: 10px 18px;
}
.press-slider-container .btn-outline-primary:hover {
  color: #1155cb;
  background-color: transparent;
  text-decoration: none;
}
.p-s-head .btn-outline-primary{
  border: none;
}
.p-s-head{
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.soc2-c-section{
  padding-bottom: 60px;
}
.soc2-c-section img{
  width: 180px;
}
.soc2-c-section .soc-flex{
  gap: 2.5rem;
}
.soc2-c-section .p1{
  font-size: 24px;
}
.soc2-c-section .p2{
  font-size: 16px;
}
/* -------------------- Footer css --------------------- */
.footer-container {
  background-color: #010304;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    270.01deg,
    rgba(255, 255, 255, 0) -1.83%,
    rgba(255, 255, 255, 0.75) 48.34%,
    rgba(255, 255, 255, 0) 103.12%
  );
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.footer-logo {
  width: 64px;
  margin-top: 55px;
}
.foot-h-text {
  font-size: 14.5px;
  font-weight: 600px;
  color: #808080;
}
.mt-100 {
  margin-top: 105px;
}
.foot-text {
  font-size: 14px;
  font-weight: 400;
  color: #e6e6e6;
}
.foot-line {
  height: 1px;
  background: #282828;
}
.f-bottom-text {
  color: var(--secondary-grey, #808080);
  font-size: 12px;
  font-weight: 400;
}
.f-bottom-text p {
  margin: 0;
}
.mt-12 {
  margin-top: 12px;
}
.g-36 {
  gap: 36px;
}

.TnC-container {
  margin-top: 150px;
  margin-bottom: 100px;
}
.TnC-container h1 {
  margin-bottom: 30px;
}
.TnC-container p{
  line-height: 26px;
}
.TnC-container p font{
  font-size: 18px;
  font-weight: bold;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

/* Cookies consent css */
.c-consent{
  box-sizing: border-box;
  margin: 2px auto;
  position: fixed;
  padding: 0px 16px;
  left: 16px;
  right: 16px;
  bottom: 0px;
  max-width: 1200px;
  z-index: 999;
}
.c-consent .alert{
  margin-bottom: 0px !important;
  align-items: center;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.c-consent .c-contents{
  box-sizing: border-box;
  padding: 12px 0px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.c-contents button{
  font-size: 14px;
  text-wrap: nowrap;
}
.c-consent .alert-dismissible .btn-close {
  padding: 10px !important;
}
.r_more{
  color: #1155cb;
  cursor: pointer;
}
/* -----------------------------------------------Voosh Year End Wrap -------------------------------------------- */
/* .t-bg-s2{
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 8.34782600402832px 25.043479919433594px 0px rgba(54, 54, 54, 0.1);
  padding: 12px;
}
.slider-home img{
  width: 500px;
  border-radius: 12px;
}
.s2-container-slider .slider-container {
  width: 625px;
}
.t-bg-s2 {
  background-image: url('../images/Slider/s-bg.png');
  background-repeat: no-repeat;
  background-position:bottom;
}
.s2-container-slider .slider-home{
  outline: none !important;
}
.s2-container-slider .h-s-text{
  font-size: 30px;
  color: #f2f2f2;
}
.s2-container-slider .slick-next {
  right: 65px;
  width: 100px;
  height: 100px;
}
.s2-container-slider .slick-prev {
  left: 55px;
  width: 100px;
  height: 100px;
  z-index: 10;
}
.s2-container-slider .slick-list{
  text-align: center !important;
  text-align: -webkit-center !important;
} */
/* Tab View */

@media screen and (min-width: 767px) and (max-width: 1023px) {
}

/* Mobile View */

@media screen and (max-width: 767px) {
  .mb60px{
    margin-bottom: 0px !important;
  }
  /*--------------- Section1 css starts ----------------------*/
  .top-alert-home{
    top: 100px;
    padding: 10px 20px;
    width: 90%;
    margin: auto;
  }
  .top-alert-home span{
    font-size: 14px;
  }

  .f-width {
    width: auto;
  }
  .s1-container {
    background-size: 100% 100%;
  }
  .modal-content{
    width: 100%;
    padding: 0 10px 0 10px;
  }
  .n-cc-r{
    padding-top: 0px;
    margin-top: -10px;
  }
  .n-cc-r span{
    display: none;
  }
  .float-imgs{
    gap: 10px;
  }
  .float-imgs img{
    height: 45px;
    margin-bottom: 20px;
  }
  .hero-data {
    padding: 110px 10px 260px 10px;
    text-align: center;
  }
  .hero-primary-text {
    margin-top: 1rem;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 8px;
  }
  .hero-secondary-text {
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
  }
  .s1-container .btn-primary {
    padding: 10px 10px;
    margin-top: 14px;
    margin-bottom: 20px;
  }
  .s1-container .btn-text {
    font-size: 15px;
  }

  .shake-effect {
    line-height: 50px;
    border-radius: 15px 5px;
  }
  .caption-text div{
    font-size: 0.85rem;
  }
  /*--------------- Section2 css starts ----------------------*/

  /* -------------------------need to change for s2-container ------------------------- */
  .s2-container {
    margin-top: -240px;
    padding: 0 20px 0 20px;
  }
  /* .s2-container-slider{
    margin-top: -220px;
    padding: 0 10px 0 10px;
  }
  .s2-container {
    margin-top: 40px;
    padding: 0 20px 0 20px;
  } */

  /* .t-bg-s2{
    padding: 6px;
  }
  .slider-home img{
    width: 100%;
  }
  .s2-container-slider .slider-container {
    width: 100%;
  }
  
  .s2-container-slider .h-s-text{
    font-size: 22px;
    color: #ffffff;
    text-align: left !important;
  }
  .s2-container-slider .slick-next {
    right: 40px;
    width: 15px;
    height: 15px;
  }
  .s2-container-slider .slick-prev {
    left: 40px;
    width: 15px;
    height: 15px;
  } */
  /* -------------------------need to change for s2-container ------------------------- */
  
  .t-ded {
    right: 110px;
  }
  .img-white {
    filter:brightness(0.5) !important;
  }
  .s2-container .card-head-text p {
    color: #808080;
  }
  .d-mgr {
    color: #f3f3f3 !important;
  }
  .card-sub-text2 {
    font-size: 26px;
  }
  .atop-size{
    width: 20px;
    height: 20px;
  }
  /*--------------- Section3 css starts ----------------------*/
  .s2b_text {
    font-size: 18px;
    font-weight: 600;
  }
  .logo-slider {
    --image-size: 60px;
  }
  .wrapper{
    gap: 2.5rem;
  }
  .wrapper img{
    width: 5rem;
  }
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-23 * 7.5rem));
    }
  }

  /*--------------- Section4 css starts ----------------------*/
  .s4-container {
    margin-top: 10px;
  }
  .s4-text {
    font-size: 22px;
  }
  .btn-with-arrow {
    font-size: 16px;
  }
  .m-arrow-btn {
    text-align: left !important;
    padding-bottom: 20px;
    padding-top: 0px !important;
  }
  .dispute-card .h-t2{
    font-size: 32px;
  }
  .review_manager_card .h-t2{
    font-size: 32px !important;
  }
  .finance-recon .h-t2{
    font-size: 32px !important;
  }
  .finance-recon .h-t2 br{
    display: none;
  }
  .s4-small-card p{
    margin-bottom: 20px !important;
  }
  .s4-small-card .p-24{
    padding: 20px 10px !important;
  }
  .brands-btn, .brands-btn2 {
    gap: 5px;
  }
  .brands-btn div, .brands-btn2 div {
    height: min-content;
    padding: 5px;
    font-size: 10px;
    font-weight: 600;
  }
  
  /* -------------------- Footer css mobile view --------------------- */

.footer-logo {
  width: 64px;
  margin-top: 55px;
}
.foot-text br{
  display: none !important;
}
.mt-100 {
  margin-top: 30px;
}
.foot-text {
  font-size: 14px;
}
.foot-bramds-mv img{
  margin-right: 20px !important;
}
.g-36 {
  justify-content: space-around;
  margin-bottom: 10px;
}
.text-c-mv{
  margin-top: 20px;
  text-align: center !important;
}

  @media only screen and (max-width: 500px) {
    .homepage-dispute-image {
      width: 80vw;
      margin-bottom: 20px;
    }

    .fgif {
      width: 65vw;
    }

    .stats-graph {
      width: 75vw;
    }

    .homepage-cards {
      margin-bottom: 10%;
    }

    .review_manager_card {
      margin-bottom: 10%;
    }
  }

  /*--------------- Section5 css starts ----------------------*/
  .s5-container {
    padding: 0 20px 20px 20px;
    /* padding: 0 20px 80px 20px; */
  }
  .section-head {
    font-size: 24px;
  }
  .section-subhead {
    font-size: 16px;
  }
  .card-title {
    font-size: 16px;
    font-weight: 600;
  }
  .card {
    max-width: 95% !important;
  }
  .press-slider .card{
    margin: inherit;
    max-width: 330px !important;
  }
  .case-studies-slider {
    width: 150% !important;
  }
  .slick-dots {
    bottom: -30px;
  }
  .slick-list {
    padding: 0 !important;
  }

  .soc2-c-section .soc-flex{
    gap: 1.5rem;
    padding: 0px 15px;
  }
  .soc2-c-section .p1{
    font-size: 20px;
  }
  .soc2-c-section .p2{
    font-size: 14px;
    margin-bottom: 0;
  }
  /*--------------- Section6 css starts ----------------------*/
  .s6-container {
    padding: 50px 20px 50px 20px;
  }
  .thumb-text-btn {
    font-size: 16px;
    border-radius: 0px;
  }
  .play_btn {
    padding: 10px;
  }
  .testimonials {
    padding: 15px;
  }
  .testimonials .t-profile {
    height: 50px;
  }
  .testimonials .t-bite {
    font-size: 14px;
  }
  .form-section {
    padding: 50px 20px 0 20px;
  }
  .form-header {
    font-size: 20px;
  }
  .s6-container .slick-dots {
    left: -0%;
  }
  .press-slider-container .slick-dots {
    left: -35%;
    bottom: -12%;
  }
  .press-cards .card{
    margin: auto;
    margin-bottom: 24px;
  }
  /* Cookies consent css */
.c-consent{
  padding: 0px 2px;
  left: 6px;
  right: 6px;
}
.c-consent .alert{
  font-size: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.c-consent .c-contents{
  padding: 5px 0px;
  gap: 8px;
}
.c-consent .alert-dismissible .btn-close {
  padding: 5px !important;
}
}
